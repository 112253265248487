import axios from "axios";
import { BookingForTPV } from "./DomainModel/BookingForTPV";
import { BookTrip } from "./DomainModel/BookTrip";
import { Extra } from "./DomainModel/Extra";
import { FilteredResult } from "./DomainModel/FilteredResult";
import { Slot } from "./DomainModel/Slot";
import { Trip } from "./DomainModel/Trip";

const baseUrl = `${process.env.GATSBY_BACKEND_API_URL}/api`;

export async function getTrips(): Promise<FilteredResult<Trip>> {
  const { data } = await axios.get<FilteredResult<Trip>>(`${baseUrl}/trips`);

  return data;
}

export async function getSlots(tripId: string): Promise<FilteredResult<Slot>> {
  const { data } = await axios.get<FilteredResult<Slot>>(`${baseUrl}/trips/${tripId}/slots`);
  return data;
}

export async function getExtras(tripId: string): Promise<Extra[]> {
  const { data } = await axios.get<Extra[]>(`${baseUrl}/trips/${tripId}/extras`);
  return data;
}

export async function bookTrip(booking: BookTrip): Promise<BookingForTPV> {
  const { data } = await axios.post<BookingForTPV>(`${baseUrl}/bookings/book`, booking);
  return data;
}

export async function searchTrips(
  start: string,
  end: string,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean,
  sunday: boolean
): Promise<TripSearchResult[]> {
  let url = `${baseUrl}/trips/search?start=${start}&end=${end}`;

  url = monday ? url + `&monday=true` : url;
  url = tuesday ? url + `&tuesday=true` : url;
  url = wednesday ? url + `&wednesday=true` : url;
  url = thursday ? url + `&thursday=true` : url;
  url = friday ? url + `&friday=true` : url;
  url = saturday ? url + `&saturday=true` : url;
  url = sunday ? url + `&sunday=true` : url;

  const { data } = await axios.get<TripSearchResult[]>(url);

  return data;
}
