import { Extra } from "./Extra";

export type BookTrip = {
  slotId: string;
  departureOriginTime: string;
  departureDestinationTime: string;
  numberOfAdults: number;
  numberOfChildren: number;
  email: string;
  fullName: string;
  phone: string;
  addressLine: string;
  postCode: string;
  country: string;
  language: string;
  applyResidentsDiscount: boolean;
  applyWebDiscount: boolean;
  source: string;
  extras: Extra[];
  ignoreDiscounts: boolean;
};

export const defaultBookTrip: BookTrip = {
  slotId: "",
  numberOfAdults: 0,
  numberOfChildren: 0,
  departureOriginTime: "",
  departureDestinationTime: "",
  email: "",
  fullName: "",
  phone: "",
  addressLine: "",
  postCode: "",
  country: "",
  applyWebDiscount: false,
  applyResidentsDiscount: false,
  language: "",
  source: "",
  extras: [],
  ignoreDiscounts: false,
};
