import { createContext, useState } from "react";
import { SearchContextType } from "./SearchContextType";

export const SearchContext = createContext<SearchContextType>({} as SearchContextType);

type SearchContextProviderProps = {
  children: React.ReactNode;
};

const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [mondaySelected, setMondaySelected] = useState(false);
  const [tuesdaySelected, setTuesdaySelected] = useState(false);
  const [wednesdaySelected, setWednesdaySelected] = useState(false);
  const [thursdaySelected, setThursdaySelected] = useState(false);
  const [fridaySelected, setFridaySelected] = useState(false);
  const [saturdaySelected, setSaturdaySelected] = useState(false);
  const [sundaySelected, setSundaySelected] = useState(false);

  const clearContext = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setMondaySelected(false);
    setTuesdaySelected(false);
    setWednesdaySelected(false);
    setThursdaySelected(false);
    setFridaySelected(false);
    setSaturdaySelected(false);
    setSundaySelected(false);
  };

  return (
    <SearchContext.Provider
      value={{
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        mondaySelected,
        tuesdaySelected,
        wednesdaySelected,
        thursdaySelected,
        fridaySelected,
        saturdaySelected,
        sundaySelected,
        setMondaySelected,
        setTuesdaySelected,
        setWednesdaySelected,
        setThursdaySelected,
        setFridaySelected,
        setSaturdaySelected,
        setSundaySelected,
        clearContext,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
