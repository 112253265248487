exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("./../../../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-cala-sant-vicenc-tsx": () => import("./../../../src/pages/cala-sant-vicenc.tsx" /* webpackChunkName: "component---src-pages-cala-sant-vicenc-tsx" */),
  "component---src-pages-contract-conditions-tsx": () => import("./../../../src/pages/contract-conditions.tsx" /* webpackChunkName: "component---src-pages-contract-conditions-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-dolphins-tsx": () => import("./../../../src/pages/dolphins.tsx" /* webpackChunkName: "component---src-pages-dolphins-tsx" */),
  "component---src-pages-formentor-beach-tsx": () => import("./../../../src/pages/formentor-beach.tsx" /* webpackChunkName: "component---src-pages-formentor-beach-tsx" */),
  "component---src-pages-formentor-point-tsx": () => import("./../../../src/pages/formentor-point.tsx" /* webpackChunkName: "component---src-pages-formentor-point-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-pollensa-bay-tsx": () => import("./../../../src/pages/pollensa-bay.tsx" /* webpackChunkName: "component---src-pages-pollensa-bay-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */)
}

