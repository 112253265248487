import { startOfDay } from "date-fns";
import React, { createContext, useEffect, useState } from "react";
import { getExtras, getSlots, getTrips } from "../../api/bookingApi";
import { TripContextType } from "./TripContextType";
import { BookTrip, defaultBookTrip } from "../../api/DomainModel/BookTrip";
import { Extra } from "../../api/DomainModel/Extra";
import { Slot } from "../../api/DomainModel/Slot";
import { Trip } from "../../api/DomainModel/Trip";

export const TripContext = createContext<TripContextType>({} as TripContextType);

type BookingContextProviderProps = {
  children: React.ReactNode;
};

const BookingContextProvider = ({ children }: BookingContextProviderProps) => {
  const [selectedTrip, setSelectedTrip] = useState<Trip>();
  const [booking, setBooking] = useState<BookTrip>(defaultBookTrip);
  const [selectedSlot, setSelectedSlot] = useState<Slot>();
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [extras, setExtras] = useState<Extra[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const selectTrip = (tripId: string) => {
    let trip = trips.find((x) => x.id === tripId);

    if (trip) {
      setSelectedTrip({ ...trip });

      setLoadingSlots(true);
      setBooking((x) => ({
        ...x,
        ignoreDiscounts: trip.ignoreDiscounts,
      }));

      getExtras(trip.id).then((response) => {
        setExtras(response);
        getSlots(trip.id).then((x) => {
          setSlots(x.data);
          setLoadingSlots(false);
        });
      });
    }
  };

  const selectDate = (date: Date) => {
    setSelectedDate(date);

    let slot = slots.find((x) => startOfDay(new Date(x.slotDate.startDate)).toString() === startOfDay(date).toString());

    setSelectedSlot(slot);

    if (slot) {
      setBooking({ ...booking, slotId: slot.id });
    }
  };

  const selectExtra = (extraItem: Extra) => {
    let extras = [...booking.extras];

    let extraFound = booking.extras.find((x) => x.extraId === extraItem.extraId);

    if (extraFound) {
      if (extraItem.amount > 0) {
        extras = extras.map((x) => (x.extraId === extraItem.extraId ? { ...extraItem } : x));
      } else {
        extras = extras.filter((x) => x.extraId !== extraItem.extraId);
      }
    } else {
      if (extraItem.amount > 0) {
        extras = [...booking.extras, extraItem];
      }
    }

    setBooking({ ...booking, extras: extras });
  };

  const applyResidentsDiscount = (apply: boolean) => {
    setBooking({ ...booking, applyResidentsDiscount: apply });
  };

  const setNumberOfAdults = (value: number) => {
    setBooking({ ...booking, numberOfAdults: value });
  };

  const setNumberOfChildren = (value: number) => {
    setBooking({ ...booking, numberOfChildren: value });
  };

  const increaseNumberOfChildren = () => {
    setBooking({
      ...booking,
      numberOfChildren: booking.numberOfChildren + 1,
    });
  };
  const decreaseNumberOfChildren = () => {
    setBooking({
      ...booking,
      numberOfChildren: booking.numberOfChildren > 0 ? booking.numberOfChildren - 1 : 0,
    });
  };
  const increaseNumberOfAdults = () => {
    setBooking({
      ...booking,
      numberOfAdults: booking.numberOfAdults + 1,
    });
  };
  const decreaseNumberOfAdults = () => {
    setBooking({
      ...booking,
      numberOfAdults: booking.numberOfAdults > 0 ? booking.numberOfAdults - 1 : 0,
    });
  };

  useEffect(() => {
    getTrips()
      .then((x) => {
        setTrips(x.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <TripContext.Provider
      value={{
        trips,
        selectedTrip,
        selectTrip,
        booking,
        setBooking,
        selectedSlot,
        setSelectedSlot,
        extras,
        slots,
        loadingSlots,
        selectDate,
        selectedDate,
        selectExtra,
        applyResidentsDiscount,
        setNumberOfAdults,
        setNumberOfChildren,
        increaseNumberOfChildren,
        decreaseNumberOfChildren,
        increaseNumberOfAdults,
        decreaseNumberOfAdults,
      }}
    >
      {children}
    </TripContext.Provider>
  );
};

export default BookingContextProvider;
