import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./src/assets/scss/theme.scss";

import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enGB } from "date-fns/locale";
import BookingContextProvider from "./src/components/ContextProviders/BookingContextProvider";
import SearchContextProvider from "./src/components/ContextProviders/SearchContextProvider";

export const wrapRootElement = ({ element }: any) => (
  <>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <BookingContextProvider>
        <SearchContextProvider>{element}</SearchContextProvider>
      </BookingContextProvider>
    </LocalizationProvider>
  </>
);
